@BASE_PATH: /user-login;
.page {
  color: #FFF;
  // height: 100vh;
  // position: relative;
  .container{
    width: 954px;
    margin: 56px auto;
    .title{
      color: #FFF;
      font-size: 28px;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 40px;
    }
    .content{
      min-height: 562px;
      border-radius: 16px;
      border: 1px solid #3F3F3F;
      background: #272727;
    }
  }
}
/* ipad */
// @media (min-width: 768px) and (max-width: 1280px) {
// }
/* mobile: We do not support medium-sized screens at the moment */
@media (max-width: 1279px) {

}

/* mobile */
@media (max-width: 767px) {
  .page {
    padding-bottom: 0px;
    .container{
      width: 100%;
      .content{
        border-width: 0;
      }
    }
  }
}
