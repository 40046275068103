@BASE_PATH: /user-login;
.email{
  .title{
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
  :global{
    .ant-input, .ant-input-affix-wrapper{
      border-color: transparent ;
      &:hover{
        border-color: #26F2D1;
      }
      &:focus{
        border-color: #26F2D1;
      }
    }
    .ant-btn-primary{
      height: 48px;
      width: 100%;
      background-color: #26F2D1;
      color: #000;
      font-weight: 700;
      border-radius: 100px;
      &:hover{
        background-color: #26F2D1;
        color: #FFF;
      }
      &:disabled{
        opacity: .3;
        color: #000;
        cursor: not-allowed;
      }
    }
  }
  .userInp{
    background: #333;
    color: #FFF;
    height: 48px;
    border-radius: 100px;
    input{
      background: #333;
    }
  }
  .sentCodeBtn{
    color: #26F2D1;
    line-height: 38px;
    height: 100%;
    font-size: 14px;
    cursor: pointer;
    background: transparent;
    border: none;
    &:disabled{
      opacity: .4;
      cursor: not-allowed;
    }
  }
  .sentDisable{
    opacity: .4;
    cursor: not-allowed;
  }
}
/* ipad */
// @media (min-width: 768px) and (max-width: 1280px) {
// }
/* mobile: We do not support medium-sized screens at the moment */
@media (max-width: 1279px) {

}

/* mobile */
@media (max-width: 767px) {
  .email{
    :global{
      .ant-input, .ant-input-affix-wrapper{
        border-color: transparent ;
        &:hover{
          border-color: #26F2D1;
        }
        &:focus{
          border-color: #26F2D1;
        }
      }
      .ant-btn-primary{
        height: 40px;
      }
    }
  }
}