@BASE_PATH: /user-login;
.social-media-icon-wrapper {
  grid-column-gap: 24px;
  grid-row-gap: 8px;
  display: flex;
  .socialIcon:hover {
    fill: #26f2d1;
  }
}

@media screen and (min-width: 1920px) {
  .dex-footer {
    width: 1440px;
  }
}
@media screen and (min-width: 1200px) {
  .dex-footer {
    width: 1200px;
  }
}
.dex-footer-wrapper {
  width: 100vw;
  display: flex;
  justify-content: center;
  background: #111111;
  border-top: 1px solid #3F3F3F;
  position: relative;
  z-index: 2;
  // position: absolute;
  // bottom: 0;
  // background: #202020;
}
.dex-footer {
  width: 1200px;
  // padding-top: 30px;
  // padding-bottom: 30px;   //仅是首页的footer的样式
  grid-column-gap: 16px;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.copyright {
  color: rgba(255, 255, 255, 0.4);
  font-size: 18px;
  font-weight: 400;
}