@BASE_PATH: /user-login;

.header{
  height: 72px;
  padding: 0 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #3F3F3F;
  background: #111111;
}
.header-logo-link{
  line-height: 100%;
}
.header-content-wrapper{
  display: flex;

}

.navWrapper{
  white-space: nowrap;
}

.socialIcon:hover{
  fill: #26f2d1;
}


//多语言
.langItem:hover{
  background: #26F2D1;
  color: #2D2D2D;
  border-radius: 4px;
  padding: 8px 20px;
  margin: 4px 0;

}
.langItem{
  padding: 8px 20px;
  margin: 4px 0;
}
.langItemActive{
  color:#26F2D1;
}

// 多语言下拉框 
.langDropDown{
  :global{
    .ant-dropdown-menu{
      background: #2D2D2D;
      box-shadow: none;
      text-align: center;
      .ant-dropdown-menu-item{
        color: #FFF ;
        align-items: center;
        white-space: nowrap;
        list-style: none;
        line-height: 22px;
        padding: 5px 16px;
        justify-content: center;
      }
    }   
  }
  background: #2D2D2D;
  border-radius: 8px;
  padding: 5px 0;
}

.langIcon{
  height: 24px;
  cursor: pointer;
}

@media screen and (min-width: 992px){
 
  .langIcon{
    display: block //仅pc展示
  }
}

  // 
.docArrow{
  display: flex;
  align-items: center;
}
.docArrow:hover{
  .arrow{
    fill: #26f2d1;
  }
}

/* mobile */
@media (max-width: 767px) {
  .header{
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    padding: 0 24px;
  }
}