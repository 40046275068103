@BASE_PATH: /user-login;
.walletLoading{
  :global{
    .ant-input, .ant-input-affix-wrapper{
      border-color: transparent ;
      &:hover{
        border-color: #26F2D1;
      }
      &:focus{
        border-color: #26F2D1;
      }
    }
    .ant-btn-primary{
      height: 48px;
      width: 100%;
      background-color: #26F2D1;
      color: #000;
      font-weight: 700;
      border-radius: 100px;
      &:hover{
        background-color: #26F2D1;
        color: #FFF;
      }
      &:disabled{
        opacity: .3;
        color: #000;
        cursor: not-allowed;
      }
    }
  }
  min-height: 562px;
  display: flex;
  align-items: center;
  flex-direction: column;
  .container{
    width: 320px;
    text-align: center;
    .desc{
      font-size: 24px;
      font-weight: 500;
      line-height: 34px;
      margin: 40px 0;
    }
    .loadingArea{
      padding: 24px 10px 16px;
      margin-bottom: 40px;
      border-radius: 8px;
      background: url('@{BASE_PATH}/images/bg-img.svg') right no-repeat, linear-gradient(98deg, #AE50A9 1.32%, #6831A6 99.71%);

      .noAddress{
        height: 20px;
        color: #FFF;
        text-align: center;
        font-size: 16px;
        margin-bottom: 24px;
      }
      .hasAddress{
        color: #FFF;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 16px;
        margin-bottom: 24px;
        .copyAddress{
          padding: 8px 16px;
          margin-top: 24px;
          display: flex;
          justify-content: space-between;
          border-radius: 100px;
          border: 1px solid #FFF;
          p{
            width: 134px;
            height: 19px;
            line-height: 19px;
            overflow: hidden;
            color: #FFF;
            text-align: left;
            font-size: 16px;
            font-weight: 400;
            margin: 0 8px;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
      & > p{
        color: #FFF;
        text-align: center;
        font-size: 12px;
        font-weight: 300;
        opacity: 0.6;
        margin: 0;
      }
    }
  }
  .confirmBtn{

  }
}